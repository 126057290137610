import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./css/tdf.core.css";
import "./css/tdf_mobil.core.css";
import "./css/colortheme-oceanside.css";
import "./js/tdf.core.js";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NotFound from './structure/NotFound.js';
import Institucional from './structure/Pages/Institucional/Institucional.js';
import Governador from './structure/Pages/Institucional/governador.js';
import GuiaTramites from './structure/Pages/GuiaDeTramites/guiaTramites.js';
import UsoInterno from './structure/Pages/UsoInterno/usoInterno.js';
import BienestarCiudadano from './structure/Pages/BienestarCIudadano/bienestarCiudadano.js';
import InteractiveMap from './structure/InteractiveMap/interactiveMap.js';
import DefaultLayout from './structure/DefaultLayout.js';
import NoHeaderFooterLayout from './structure/NoHeaderFooterLayout.js';
import Widgets from './structure/Widgets/Widgets.js';
import Home from './Home.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <div id="tdf-root" style={{ height: "100%" }}>
    {/* <React.StrictMode> */}
      <Router>
        <Routes>
          {/* Rutas que usan el layout con Header y Footer */}
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/institucional" element={<Institucional />} />
            <Route path="/institucional/gobernador" element={<Governador />} />
            <Route path="/tramites" element={<GuiaTramites />} />
            <Route path="/uso-interno" element={<UsoInterno />} />
            <Route path="/servicios" element={<BienestarCiudadano />} />
          </Route>
          {/* Ruta que usa el layout sin Header y Footer */}
          <Route element={<NoHeaderFooterLayout />}>
            <Route path="/mapa-interactivo" element={<InteractiveMap />} />
          </Route>
        </Routes>
      </Router>
      <Widgets />
    {/* </React.StrictMode> */}
  </div>
);

reportWebVitals();