import React from 'react';

const NotFound = () => {
    return (
        <div class="h-[500px] flex flex-grow items-center justify-center bg-gray-50">
            <div class="rounded-lg bg-white p-8 text-center shadow-xl">
                <h1 class="mb-4 text-4xl font-bold">404</h1>
                <p class="text-gray-600">Ups! La pagina que estas buscando no existe</p>
                <a href="/" class="mt-4 inline-block rounded bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600"> Ir a inicio </a>
            </div>
        </div>
    );
};

export default NotFound;