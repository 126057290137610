import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch.js';
import config from '../../../config.json';

const BienestarCiudadano = () => {
    const [idUrl, setIdUrl] = useState(new URLSearchParams(window.location.search).get('result_id'));
    const [procedure, setProcedure] = useState({
        header: {
            title: "",
            subtitle: "",
            color: ""
        },
        procedures: [],
        info_additional: {
            title: "",
            html: "",
            subtitle: "",
            begin_procedure_location: "",
            procedure_cost: "",
            procedure_type: "",
            who_can_do_procedure: "",
            procedure_documentation: "",
            subsidiaries: []
        }
    });

    const { data, isLoading, error, fetchData } = useFetch(
        'GET',
        `${config.API_URL}/maps/procedures.php?subprocedure=${idUrl}&procedure_type=BC&action=json&source=Y2h1`,
        null,
        {}
    );

    const { data: breadcrumbData, fetchData: fetchBreadcrumbs } = useFetch(
        'GET',
        `${config.API_URL}/maps/procedures_front.php?action=json&broad=1&id_broad=${idUrl}&source=Y2h1`,
        null,
        {}
    );

    const router = useNavigate();

    useEffect(() => {
        fetchBreadcrumbs();
    }, [idUrl]);

    useEffect(() => {
        fetchData();
    }, [idUrl]);

    useEffect(() => {
        if (data) {
            setProcedure(data);
        }
    }, [data]);

    const handleProcedureClick = (procedureId) => {
        window.location.href = `/servicios/?result_id=${procedureId}`;
    };

    const renderBreadcrumbs = () => {
        const isMobile = window.innerWidth <= 768;

        if (!breadcrumbData || !breadcrumbData.procedures) {
            return null;
        }

        const reversedProcedures = breadcrumbData.procedures.slice().reverse();

        if (isMobile) {

            const secondLastBreadcrumb = reversedProcedures.length > 1 ? reversedProcedures[1] : reversedProcedures[0];

            return (
                <div style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                    <span style={{ display: 'inline-flex', alignItems: 'center', color: 'white' }}>
                        ...
                    </span>
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6 6L1 11" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="box-navigator box-navigator-black flex">

                        <span
                            onClick={() => window.location.href = `/servicios?result_id=${secondLastBreadcrumb.procedure_id}`}
                            className="font-semibold "
                            style={{
                                color: 'black',
                                marginLeft: '8px',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                textDecorationColor: 'white',
                                fontSize: '16px'
                            }}
                        >
                            {secondLastBreadcrumb.title}
                        </span>
                    </div>

                </div>
            );
        }

        return (
            <div key={JSON.stringify(breadcrumbData)} style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                {reversedProcedures.map((item, index) => (
                    <div class="flex items-center">
                        <div className='box-navigator box-navigator-black flex'>
                            <span className="" key={item.procedure_id} style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <span
                                    onClick={() => window.location.href = `/servicios?result_id=${item.procedure_id}`}
                                    className="font-semibold text-sm-0 w-fit"
                                    style={{

                                        cursor: 'pointer',
                                        textDecoration: index === reversedProcedures.length - 1 ? 'none' : 'none'
                                    }}
                                >
                                    {item.title}
                                </span>
                                </span>
                        </div>

                    
                        {
                        index<reversedProcedures.length - 1 && (
                            <svg className='mx-2' width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6 6L1 11" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        )
                    }
                    </div>
        ))
    }
            </div >
        );
    };

if (isLoading) {
    return (
        <div className="container-fluid w-full flex h-[500px] justify-center items-center ">
            <style>
                {`
          .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
        `}
            </style>
            <div className="loader"></div>
        </div>
    );
}

if (error || !data) {
    return (
        <div className="container-fluid w-full flex h-[500px] justify-center items-center  caja-contenido" style={{ height: '524px !important' }}>
            <p>Error al cargar los datos.</p>
        </div>)
}

if (!procedure) {
    return <p>Error: No se encontró el procedimiento.</p>;
}

if (!procedure.info_additional) {
    return (
        <>
            <div style={{ height: '444px', backgroundColor: '#F6F6F6' }}>
                <div className='container flex align-items-center w-full ' style={{ height: '57px', paddingTop: '190px' }}>
                    <div class="box-navigator flex">
                        <span onClick={() => router('/')} className='text-sm-0 self-center cursor-pointer cargo-white breadcrumb-mobi'>
                            Inicio
                        </span>
                    </div>

                    <svg class="mx-2" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6 6L1 11" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    {renderBreadcrumbs()}


                </div>
                <style jsx>{`
                    .img-header {
                        float:right;
                        position:absolute;
                        right:0px;
                        margin-top:99px;
                    }
                    `}</style>
                <div className='pt-8 header-title-mobil' >
                    <div class="img-header">
                        <img src="https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/header_path.png"></img>
                    </div>
                    <div className="container align-items-center w-full "><br></br>
                        <h1 className="titulo-interno-header">{procedure.header.title}</h1>
                        <p className="titulo-interno-subtitulo">{procedure.header.subtitle}</p>
                    </div>

                </div>
            </div>
            <div style={{ backgroundColor: '#FFF', paddingTop: '77px' }}>
                <main className="container flex align-items-center w-full border-FEA footer-distancia">
                    <div
                        className={`mt-6 grid gap-6 
    ${procedure.procedures.length <= 2
                                ? 'grid-cols-1 sm:grid-cols-2 justify-center'
                                : 'grid-cols-1 sm:grid-cols-3'}
  `}
                    >
                        {procedure.procedures.map((proc) => (
                            <a class="cursor-pointer" onClick={() => handleProcedureClick(proc.id)}>
                                <div key={proc.id} class="bg-white shadow-md flex box-mobil boxes-border" style={{ height: '158px', borderRadius: '18px !important' }}>

                                    {proc.icon === '' && null}

                                    {proc.icon != '' ?

                                        <div class="flex items-center justify-center" style={{ backgroundColor: '#EFEFEF', width: '5em', marginLeft: '5px', marginBottom: '5px', marginTop: '5px', borderRadius: '18px' }}>
                                            <img src={proc.icon} class="h-16 "></img>
                                        </div>

                                        :

                                        <div class="flex items-center justify-center" style={{ backgroundColor: '#EFEFEF', width: '5em', marginLeft: '5px', marginBottom: '5px', marginTop: '5px', borderRadius: '18px' }}>
                                        </div>}

                                    <div class="flex items-center ml-4">
                                        <div class="flex-col">
                                            <h3 class="text-xl font-bold w-[275px] font-box-mobil">{proc.title}</h3>


                                        </div>
                                        <div><svg class="" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="white" />
                                            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#DADADA" />
                                            <path d="M15 12.5L21 18L15 23.5" stroke="#1C2026" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></div>
                                    </div>
                                </div></a>
                        ))}
                    </div>
                </main>
            </div>
        </>
    )
}
else {
    return (
        <>
            <div style={{ height: '444px', backgroundColor: '#F6F6F6' }}>
                <div className='container flex align-items-center w-full ' style={{ height: '57px', paddingTop: '190px' }}>
                    <div class="box-navigator flex">
                        <span onClick={() => router('/')} className='text-sm-0 self-center cursor-pointer cargo-white breadcrumb-mobi'>
                            Inicio
                        </span>
                    </div>

                    <svg class="mx-2" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6 6L1 11" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    {renderBreadcrumbs()}


                </div>
                <style jsx>{`
                    .img-header {
                        float:right;
                        position:absolute;
                        right:0px;
                        margin-top:99px;
                    }
                    `}</style>
                <div className='pt-8 header-title-mobil' >
                    <div class="img-header">
                        <img src="https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/header_path.png"></img>
                    </div>
                    <div className="container align-items-center w-full "><br></br>
                        <h1 className="titulo-interno-header">{procedure.header.title}</h1>
                        <p className="titulo-interno-subtitulo">{procedure.header.subtitle}</p>
                    </div>

                </div>
            </div>
            <div class="pt-8 pb-8 container">
                {procedure.info_additional.seccond_title && (
                    <div class="h-auto border-b">
                        <h1 class="text-2xl font-semibold mb-4">{procedure.info_additional.seccond_title}</h1>
                    </div>
                )}

                <div class="mb-6 mt-6">
                    <div
                        className="mb-6 text-lg"
                        dangerouslySetInnerHTML={{ __html: procedure.info_additional.html }}
                    ></div>
                </div>


                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">

                    {procedure.info_additional.subsidiaries.map((item, idx) => (
                        <div key={idx} class=" border">
                            <div class="flex items-center mb-2 h-12 border-b ">

                                <h4 class="text-lg font-semibold flex">
                                    <svg class="mr-2 ml-4" width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.19968 0C4.12836 0 0 4.12672 0 9.19603C0 16.846 8.16472 22.9901 9.19968 22.9901C10.2346 22.9901 18.3994 16.846 18.3994 9.19603C18.3994 4.12672 14.271 0 9.19968 0ZM9.19968 21.8061C7.87723 21.1624 1.14996 15.7999 1.14996 9.19603C1.14996 4.75894 4.76084 1.1495 9.19968 1.1495C13.6385 1.1495 17.2494 4.75894 17.2494 9.19603C17.2494 15.8057 10.5221 21.1624 9.19968 21.8061Z" fill="#383838" />
                                        <path d="M9.19967 5.1731C6.98024 5.1731 5.1748 6.97782 5.1748 9.19636C5.1748 11.4149 6.98024 13.2196 9.19967 13.2196C11.4191 13.2196 13.2245 11.4149 13.2245 9.19636C13.2245 6.97782 11.4191 5.1731 9.19967 5.1731ZM9.19967 12.0701C7.61272 12.0701 6.32477 10.7827 6.32477 9.19636C6.32477 7.61004 7.61272 6.3226 9.19967 6.3226C10.7866 6.3226 12.0746 7.61004 12.0746 9.19636C12.0746 10.7827 10.7866 12.0701 9.19967 12.0701Z" fill="#383838" />
                                    </svg>

                                    {item.location}</h4>
                            </div>
                            <div class="p-4">
                                <p class="font-semibold mb-2">{item.department}</p>
                                <p class="mb-2 font-semibold text-sm" > <span class="block font-normal text-sm">Descripción del Trámite:</span> {item.procedure_description}</p>
                                <div class="grid grid-cols-2 gap-2">
                                    <p class="mb-2 text-sm font-semibold"><span class="block font-normal">• Dirección:</span> {item.address}</p>
                                    <p class="mb-2 text-sm font-semibold"><span class="block font-normal">• Horario de atención:</span> {item.hours}</p>
                                    <p class="mb-2 text-sm font-semibold"><span class="block font-normal">• Teléfonos:</span> {item.telephone}</p>
                                </div>
                            </div>
                            <  div class="h-12 bg-gray-100 flex items-center text-sm">
                                <p class="ml-6 ">Mail: <a class="font-semibold">{item.mail}</a></p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
}

const truncateText = (text, maxLength = 120) => {
    if (text.length <= maxLength) {
        return text;
    }

    const truncated = text.slice(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    return lastSpaceIndex > 0 ? truncated.slice(0, lastSpaceIndex) + '...' : truncated + '...';
};

export default BienestarCiudadano